import React, { Suspense } from 'react';
import { MainLayout, LoadingPage } from 'components/Layout';
import { withTranslation } from "react-i18next";

// code splitting
const Hero = React.lazy(() => import('components/Layout/Hero'));
const Client = React.lazy(() => import('components/Section/Client'));
const LetsAction = React.lazy(() => import('components/Section/LetsAction'));
const Service = React.lazy(() => import('components/Section/Service'));
const Works = React.lazy(() => import('components/Section/Works'));
const Value = React.lazy(() => import('components/Section/Value'));

const Layout = withTranslation()(MainLayout);

function App() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Layout>
        <Hero />
        <Service />
        <Works />
        <Value />
        <Client />
        <LetsAction />
      </Layout>
    </Suspense>
  );
}

export default App;
