// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA0lU9bwzYo9RRTY87iQerWv61_yF5WFHg",
  authDomain: "potofolio-mas-bangkit.firebaseapp.com",
  projectId: "potofolio-mas-bangkit",
  storageBucket: "potofolio-mas-bangkit.appspot.com",
  messagingSenderId: "809812624175",
  appId: "1:809812624175:web:200e12fe8b2af58f2357da"
};

// Initialize Firebase
initializeApp(firebaseConfig);