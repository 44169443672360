import Zoom from 'react-reveal/Zoom';
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-hero h-[28rem] flex items-center justify-center text-center flex-col">
      <div className="max-w-xl">
        <Zoom>
          <p className="font-poppins font-extrabold fontHeroSize text-primary-gray">
            {t('something')} <br /><span className="text-primary-blue -mt-3">{t('canBeSpecial')}</span>
          </p>
          <p className="font-poppins mt-4 lg:mt-6 text-sm md:text-base lg:text-xl text-primary-gray font-medium">
            {t('makeSomething')}
          </p>
        </Zoom>
      </div>
    </div>
  )
}

export default Hero;
