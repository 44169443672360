import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// import multi language
import './utils/i18nextInit';
// import firebaseConfig
import './utils/firebase';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback="...">
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>
);
