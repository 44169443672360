import Zoom from 'react-reveal/Zoom';

const Footer = () => {
  return (
    <div className="font-poppins min-h-52 h-full justify-center flex p-4 pt-6 bg-[#211E3F] text-white">
      <Zoom>
        <div className="flex items-center justify-center text-center md:text-left flex-wrap lg:py-10 py-6">
          <div className="md:w-40 w-[80%] md:m-0 m-2">
            <p className="text-lg font-bold mb-2">Ask Question</p>
            <p className="text-base font-thin">Internship</p>
            <p className="text-base font-thin">Freelance</p>
            <p className="text-base font-thin">Price List</p>
          </div>
          <div className="md:w-44 w-[80%] md:m-0 md:mx-8 m-6">
            <p className="text-lg font-bold mb-2">Contact Us</p>
            <p className="text-base font-thin">grapikit@gmail.com</p>
            <p className="text-base font-thin">Whatsapp</p>
            <p className="text-base font-thin">Telegram</p>
          </div>
          <div className="md:w-40 w-[80%] md:m-0 m-2">
            <p className="text-lg font-bold mb-2">About Us</p>
            <p className="text-base font-thin">History</p>
            <p className="text-base font-thin">Customer Stories</p>
            <p className="text-base font-thin">Team</p>
          </div>
        </div>
      </Zoom>
    </div>
  )
}

export default Footer;
