import grapikitStudioLogo from 'assets/icons/grapikitStudioLogo.png';
import { useTranslation } from 'react-i18next';

const Loading = () => {
  const { t } = useTranslation();
  return (
    <div className="flex w-screen h-screen justify-center items-center bg-white">
      <div className="flex justify-center items-center flex-col">
        <img src={grapikitStudioLogo} alt="Grapikit Studio Logo" className="w-16 h-16" />
        <p className="text-md font-base mt-1 text-primary-blue font-poppins">
          {t('loading')}
        </p>
      </div>
    </div>
  )
}

export default Loading;
