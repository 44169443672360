import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationID from '../assets/locales/id/translation.json';
import translationEN from '../assets/locales/en/translation.json';

const fallbackLng = ["en"]; // default fallback language
const availableLanguages = ["en", "id"];

const resources = {
  en: {
    translation: translationEN
  },
  id: {
    translation: translationID
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    detection: {
      checkWhitelist: true
    },
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
