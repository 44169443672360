import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const Modal = ({
  title,
  desc,
  isOpen,
  setIsOpen,
  children,
  textCenter,
  boldTitle = false,
  wider = false,
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30 overflow-y-auto bg-black bg-opacity-75"
        onClose={() => setIsOpen(false)}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={`inline-block ${wider ? 'max-w-xl w-full' : 'max-w-md w-72'
                } px-8 p-2 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl`}
            >
              <Dialog.Title
                as="h2"
                className={`text-lg font-poppins leading-6 mt-2 ${boldTitle ? 'text-black font-bold text-center' : 'text-gray-900 font-medium'
                  }`}
              >
                {title}
              </Dialog.Title>
              <div className="mt-2">
                <p
                  className={`text-sm text-gray-900 ${textCenter ? 'text-center' : ''}`}
                >
                  {desc}
                </p>
              </div>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
