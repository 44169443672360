import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import logoDesktop from 'assets/images/grapikit-logo.png';
import { Footer } from 'components/Layout';
import Zoom from 'react-reveal/Zoom';
import { Link } from 'react-scroll';
import flagId from 'assets/icons/flagId.png';
import flagEn from 'assets/icons/flagEn.png';
import { useTranslation } from "react-i18next";
import { Modal } from 'components/Modal';
import { useState } from 'react';
import i18next from "i18next";

const navigation = [
  { to: 'service', smooth: true, translate: 'service' },
  { to: 'work', smooth: true, translate: 'work' },
  { to: 'talk', smooth: true, translate: 'lets' },
]

export default function MainLayout(props) {
  const { language } = props.i18n // en or id
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Disclosure as="nav" className="bg-white bg-opacity-40 shadow w-full fixed z-50 top-0 bg-clip-padding backdrop-filter-menu">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-kafh-olive hover:text-kafh-pale hover:bg-kafh-olive focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6 text-gray-900" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  {/* image logo */}
                  <div className="flex-shrink-0 flex items-center">
                    {/* mobile view */}
                    <Zoom>
                      <img
                        className="block lg:hidden h-16 w-auto"
                        src={logoDesktop}
                        alt="Workflow"
                      />
                    </Zoom>
                    {/* desktop view 442x94 */}
                    <Zoom>
                      <img
                        className="hidden lg:block h-16 w-auto"
                        src={logoDesktop}
                        alt="Workflow"
                      />
                    </Zoom>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* menu button */}
                  <div className="sm:hidden">
                    <Zoom>
                      <img
                        onClick={() => setOpenModal(true)}
                        className="block h-8 w-auto cursor-pointer"
                        src={language === 'en' ? flagEn : flagId}
                        alt="select language"
                      />
                    </Zoom>
                  </div>
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex space-x-4 items-center">
                      {navigation.map((item) => (
                        <Zoom key={item.name}>
                          <Link
                            spy={true}
                            to={item.to}
                            smooth={item.smooth}
                            key={item.name}
                            className={`px-3 py-2 text-primary-gray rounded-md text-md font-bold font-poppins-medium cursor-pointer`}
                          >
                            {t(item.translate).toUpperCase()}
                          </Link>
                        </Zoom>
                      ))}
                      <Zoom>
                        <img
                          onClick={() => setOpenModal(true)}
                          className="block h-8 w-auto cursor-pointer"
                          src={language === 'en' ? flagEn : flagId}
                          alt="select language"
                        />
                      </Zoom>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* mobile view burger menu */}
            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Zoom key={item.name}>
                    <Disclosure.Button
                      as="div"
                      className={`block px-3 py-2 rounded-md text-base font-medium cursor-pointer`}
                    >
                      <Link
                        spy={true}
                        to={item.to}
                        smooth={item.smooth}
                        key={item.name}
                        className={`px-3 py-2 text-primary-gray rounded-md text-md font-bold font-poppins-medium cursor-pointer`}
                      >
                        {t(item.translate).toUpperCase()}
                      </Link>
                    </Disclosure.Button>
                  </Zoom>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      {props.children}
      <Footer />

      <Modal
        desc={t('selectLanguage')}
        isOpen={openModal}
        textCenter
        boldTitle={true}
        setIsOpen={() => setOpenModal(!openModal)}
      >
        <div className="mt-2 flex flex-col">
          <div className="flex items-center m-1 cursor-pointer border rounded-md p-2 hover:border-2" onClick={() => {
            i18next.changeLanguage('en')
            setOpenModal(false)
          }
          }>
            <img
              className="block h-8 w-auto cursor-pointer"
              src={flagEn}
              alt="select language"
            />
            <p className="font-poppins ml-2">English</p>
          </div>
          <div className="flex items-center m-1 cursor-pointer border rounded-md p-2 hover:border-2" onClick={() => {
            i18next.changeLanguage('id')
            setOpenModal(false);
          }
          }>
            <img
              className="block h-8 w-auto cursor-pointer"
              src={flagId}
              alt="select language"
            />
            <p className="font-poppins ml-2">Bahasa Indonesia</p>
          </div>
        </div>
      </Modal>
    </>
  )
}
